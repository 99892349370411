import { createFormControl } from "solid-forms";
import { FormControlData, FormControlValue, LabelViewProps } from "../model";

export function Label<V extends FormControlValue, D extends FormControlData>({
	control,
	title,
	children,
	...props
}: LabelViewProps<V, D>) {
	const _control = Array.isArray(control) ? createFormControl<V, D>(...control) : control;
	return (
		<label {...props} classList={{ disabled: _control.isDisabled }}>
			{title}
			{children}
		</label>
	);
}
